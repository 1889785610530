import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

declare var CafeZone: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  nonceValue: string;
  constructor(private sanitizer: DomSanitizer) {
    const buffer = new Uint8Array(32);
    window.crypto.getRandomValues(buffer);
    this.nonceValue = btoa(String.fromCharCode.apply(null, buffer));
   }

  ngOnInit(): void {
	  CafeZone.init();
    
  }
  getNonceValue() {
    return this.sanitizer.bypassSecurityTrustHtml(`'nonce-${this.nonceValue}'`);
  }
}
