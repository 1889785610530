<!-- Blog Single -->
<div class="section-full bg-pink-light">
    <div class="container">
        <div class="blog-post blog-single bg-white">
            <div class="dlab-post-text">
                <h4 class="text-primary">Restaurant Will Make You Tons Of Cash. Here's How!</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                <h4 class="text-primary">Why Is Restaurant So Famous?</h4>
                <ul>
                    <li>Lorem Ipsum is simply dummy text of the printing industry. </li>
                    <li>Lorem Ipsum has been the industry's standard dummy.</li>
                    <li>It was popularised in the 1960s with the release of Letraset.</li>
                    <li>publishing software like Aldus PageMaker including of Lorem Ipsum.</li>
                    <li>sheets containing Lorem Ipsum passages, and more recently with desktop.</li>
                </ul>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
            </div>
            <div class="author-box blog-user text-white">
                <div class="author-profile-info">
                    <div class="author-profile-pic">
                        <img src="assets/images/testimonials/pic2.jpg" alt="">
                    </div>
                    <div class="author-profile-content">
                        <p>Written By:</p>
                        <h6>John Smith</h6>
                    </div>
                </div>
                <div class="clearfix">
                    <a [routerLink] = "'/blog-detail'" class="site-button dark-tp">Back to Archive</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- blog END -->
