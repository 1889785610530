import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private audio: HTMLAudioElement;
  private audioTime: number;

  constructor() {
    this.audio = new Audio();
    this.audio.src = 'assets/audio/audio1.mp3';
    
  }

  getAudio(): HTMLAudioElement {
    return this.audio;
  }

  setAudioTime(audioTime: number): void {
    this.audioTime = audioTime;
  }

  getAudioTime(): number {
    return this.audioTime;
  }
  playAudio(): void{
    this.audio.play();
  }
}