<!-- Gallery -->
<div class="section-full content-inner-2 bg-pink-light">
    <div class="container">
        <div class="row sp20 lightgallery1">
            <div class="col-lg-6">
                <div class="row sp20">
                    
                    <div *ngFor = "let item of galleryCol1; let key = index"  class=" lightgallery {{key == 4 ? 'col-lg-12 col-md-12 col-12' : 'col-lg-6 col-md-6 col-6'}}">
                        <div class="dlab-gallery-box">
                            <div class="dlab-thum">
                                 
                                    <img [src]="item.thumb" (click)="open(galleryCol1, key)" alt="">
                            
                            </div>
                        </div>
                    </div>
                    
                    
                    
                </div>
            </div>
            <div class="col-lg-6">
                <div class="row sp20">
                    <div *ngFor = "let item of galleryCol2 let key = index"  class="lightgallery {{key == 0 ? 'col-lg-12 col-md-12 col-12' : 'col-lg-6 col-md-6 col-6'}}">
                        <div class="dlab-gallery-box">
                            <div class="dlab-thum">
                                    <img [src]="item.thumb" (click)="open(galleryCol2, key)" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Gallery END -->
