import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-section',
  templateUrl: './event-section.component.html',
  styleUrls: ['./event-section.component.css']
})
export class EventSectionComponent implements OnInit {

  event = [
    {
      image: 'pic1.jpg',
      date: '',
      category: '',
      title: 'MODERN SUSHI WORKSHOP',
      subtitle: 'Event',
      description: '',
      url: '/event-detail',
    },
    {
      image: 'pic2.jpg',
      date: '',
      category: '',
      title: 'COFFEE COMPANY WORKSHOP',
      subtitle: 'Event',
      description: '',
      url: '/event-detail',
    },
    {
      image: 'pic3.jpg',
      date: '',
      category: '',
      title: 'ALL YOU CAN EAT SPARE-RIBS',
      subtitle: 'Event',
      description: '',
      url: '/event-detail',
    }
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
