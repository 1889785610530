import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ComponentsComponent } from './components/components.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { AboutSectionComponent } from './components/about-section/about-section.component';
import { AboutServiceSectionComponent } from './components/about-service-section/about-service-section.component';
import { MenuSectionComponent } from './components/menu-section/menu-section.component';
import { HeaderComponent } from './components/header/header.component';
import { InfoSectionComponent } from './components/info-section/info-section.component';
import { FooterComponent } from './components/footer/footer.component';
import { SliderComponent } from './components/slider/slider.component';
import { AboutComponent } from './about/about.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { OwnerComponent } from './components/owner/owner.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { BlogComponent } from './blog/blog.component';
import { OurMenuComponent } from './our-menu/our-menu.component';
import { OurMenuOneComponent } from './components/our-menu-one/our-menu-one.component';
import { OurMenuTwoComponent } from './components/our-menu-two/our-menu-two.component';
import { PageBannerComponent } from './components/page-banner/page-banner.component';
import { BlogSectionComponent } from './components/blog-section/blog-section.component';
import { EventsComponent } from './events/events.component';
import { ContactComponent } from './contact/contact.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ReservationFormComponent } from './components/reservation-form/reservation-form.component';
import { GalleryComponent } from './gallery/gallery.component';
import { GallerySectionComponent } from './components/gallery-section/gallery-section.component';
import { EventSectionComponent } from './components/event-section/event-section.component';
import { BlogDetailSectionComponent } from './components/blog-detail-section/blog-detail-section.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { EventDetailSectionComponent } from './components/event-detail-section/event-detail-section.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { PageBannerTwoComponent } from './components/page-banner-two/page-banner-two.component';
import { TextBoxOneComponent } from './components/text-box-one/text-box-one.component';
import { RouterModule } from '@angular/router';

import { LightboxModule } from 'ngx-lightbox';
import { UserComponent } from './user/user.component';
import { UserService } from './shared/user.service';



import { AuthGuard }  from './services/auth.guard';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthInterceptor } from './services/auth.interceptor';
import { FormsModule } from '@angular/forms';
import { appRoutes } from './routes';
import { ToastrModule } from 'ngx-toastr';


import { AudioPlayerComponent } from './audio-player/audio-player.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatTreeModule } from '@angular/material/tree';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import {MatGridListModule} from '@angular/material/grid-list';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs'

import { CorsInterceptor } from './cors.service';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ComponentsComponent,
    SideNavComponent,
    AboutSectionComponent,
    AboutServiceSectionComponent,
    MenuSectionComponent,
    HeaderComponent,
    InfoSectionComponent,
    FooterComponent,
    SliderComponent,
    AboutComponent,
    AboutUsComponent,
    OwnerComponent,
    ContactInfoComponent,
    BlogComponent,
    OurMenuComponent,
    OurMenuOneComponent,
    OurMenuTwoComponent,
    PageBannerComponent,
    BlogSectionComponent,
    EventsComponent,
    ContactComponent,
    ContactFormComponent,
    ReservationComponent,
    ReservationFormComponent,
    GalleryComponent,
    GallerySectionComponent,
    EventSectionComponent,
    BlogDetailSectionComponent,
    BlogDetailComponent,
    EventDetailSectionComponent,
    EventDetailComponent,
    PageBannerTwoComponent,
    TextBoxOneComponent,
    UserComponent,
    AudioPlayerComponent,
   
   
  
  ],
  imports: [
    
    MatTabsModule,
    MatSidenavModule,
    MatMenuModule,
    MatButtonModule,
    MatExpansionModule,
    MatToolbarModule,
    MatTableModule,
    MatBadgeModule,
    MatSnackBarModule,
    MatGridListModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatTreeModule,
    MatIconModule,
    MatCardModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CarouselModule,
    MalihuScrollbarModule.forRoot(),
    LightboxModule,
    FormsModule,
    MatDialogModule,
    RouterModule.forRoot(appRoutes),
    ToastrModule.forRoot(), // ToastrModule added
    MatDialogModule
  ],
  providers: [UserService,AuthGuard,
    ,
    {
      provide : [
        
        HTTP_INTERCEPTORS, 
      ],
      useClass: CorsInterceptor,
      multi : true
    }],
  bootstrap: [AppComponent],
  entryComponents: [AppComponent]
})
export class AppModule { }
