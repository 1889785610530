import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  

  aboutInfo: any =
    {
      introtext: 'Vũ Khánh Coffee',
      title: 'Về chúng tôi',
      subtitle: 'Coffee & Bakery',
      description: 'Chúng tôi đi khắp thế giới để tìm kiếm cà phê tuyệt vời. Trong quá trình đó, chúng tôi phát hiện ra những hạt Robusta đặc biệt và hiếm đến nỗi chúng tôi có thể chờ đợi để mang chúng về.',
    }


    constructor() {
      
    }
    
 

  ngOnInit(): void {
    
    
    
  }


 

}
