import { Component,ElementRef, ViewChild, OnInit,Output, EventEmitter  } from '@angular/core';
import { ElementServiceService } from '../../element-service.service';
import { OwlOptions } from 'ngx-owl-carousel-o';



@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  @ViewChild('targetElement') targetElement: ElementRef;
  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    margin:0,
    nav:true,
    autoplay:true,
    autoplaySpeed: 2000,
    navSpeed: 2000,
    items: 1,
    
    navText: ['<i class="la la-angle-left"></i>', '<i class="la la-angle-right"></i>'],
    /* responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }, */
  }

  slideStore = [
    {
      introtext: "Vũ Khánh Coffee & Bakery",
      title: "Xin chào!",
      subtitle: "Tại đây chúng tôi có những hạt Robusta ngon nhất Việt Nam",
      image: "assets/images/main-slider/slide1.jpg",
    },

    {
      introtext: "Vũ Khánh Coffee & Bakery",
      title: "Cảm hứng",
      subtitle: "Không chỉ chất lượng chúng tôi còn muốn đem lại niềm hứng khởi cho bạn",
      image: "assets/images/main-slider/slide2.jpg",
    }

  ]
  
  constructor() { }
  

  ngOnInit(): void {
  }

}
