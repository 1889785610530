<!-- Events Details -->
<div class="section-full content-inner-2 overlay-white-light" style="background-image:url(assets/images/background/bg1.jpg); background-position: top right; background-repeat: no-repeat;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="event-details">
                    <div class="m-b50">
                        <h2 class="sub-title text-primary">MODERN SUSHI WORKSHOP</h2>
                        <h3 class="title">MORE INFORMATION:</h3>
                    </div>
                    <ul class="menu-list">
                        <li *ngFor="let item of menuList let key = index">
                            <div class="item-left">
                                <img src="assets/images/cup/{{item.image}}" alt=""/>
                                <h4 class="title">{{item.title}}</h4>
                                <p class="description">{{item.description}}</p>
                            </div>
                            <div class="item-right">
                                <h5 class="price">${{item.price}}</h5>
                            </div>
                        </li>
                    </ul>
                    <div class="text-left m-t30">
                        <a [routerLink] = "'/event-detail'" class="btn bg-secondry">INTERESTED? CONTACT US NOW!</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Events Details End  -->
