import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  socialicon = [
      {icon: 'fa fa-facebook'},
      {icon: 'fa fa-instagram'},
      {icon: 'fa fa-youtube'},
      {icon: 'fa fa-google-plus'}
  ];
  constructor() { }

  ngOnInit(): void {
    
    
  }

}
