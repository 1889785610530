<!-- Blog -->
<div class="section-full content-inner-2 bg-white">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div *ngFor = "let item of blog" class="blog-post blog-md">
                    <div class="dlab-post-media dlab-img-overlay1 dlab-overlay-primary"> 
                        <a [routerLink] = "item.url"><img src="assets/images/blog/blog-md/{{item.image}}" alt=""></a> 
                    </div>
                    <div class="dlab-post-info text-center">
                        <div class="dlab-post-meta">
                            <ul>
                                <li class="post-date text-primary">{{item.date}}</li>
                            </ul>
                        </div>
                        <div class="dlab-post-title">
                            <h3 class="post-title"><a [routerLink] = "'/blog-detail'">{{item.title}}</a></h3>
                        </div>
                        <div class="dlab-separator"></div>
                        <div class="dlab-post-text">
                            <p>{{item.description}}</p>
                        </div>
                        <div class="dlab-post-readmore"> 
                            <a [routerLink] = "item.url" title="READ MORE" rel="bookmark" class="btn bg-secondry">READ MORE</a> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Blog End -->
