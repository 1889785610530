import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-service-section',
  templateUrl: './about-service-section.component.html',
  styleUrls: ['./about-service-section.component.css']
})
export class AboutServiceSectionComponent implements OnInit {
  service = [
    {
      title: "Chất trên từng hạt Coffee",
      image: "icon1.png"
    },
    {
      title: "+84 931 368 962",
      image: "icon2.png"
    },
    {
      title: "Mở cửa hàng ngày 07:00 - 23:00",
      image: "icon3.png"
    },
    {
      title: "171 Phùng Chí Kiên, TP Vinh",
      image: "icon4.png"
    }
    
  ]
  











  constructor() { }

  ngOnInit(): void {
  }

}
