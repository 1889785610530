import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-menu-two',
  templateUrl: './our-menu-two.component.html',
  styleUrls: ['./our-menu-two.component.css']
})
export class OurMenuTwoComponent implements OnInit {

  menuList = []
  constructor() { }

  ngOnInit(): void {
    const rs = JSON.parse(localStorage.getItem('sessionData'));
    this.menuList = [];

    Object.values(rs).forEach((category: any) => {
      const hotItems = category.items.filter((item: any) => item.isHot === 1);
      this.menuList = this.menuList.concat(hotItems);
    });


  }

}
