<!-- Our Info -->
<div class="section-full bg-pink-light content-inner-2">
    <div class="container">
     
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="section-head full-head text-center">
                    <h2 class="main-title text-primary">Vũ Khánh Cafe & Bakery</h2>
                    <h3 class="title">Hậu vị</h3>
                    <h4 class="sub-title"></h4>
                    <div class="dlab-separator"></div>
                    <p>"Một cách để nghiền ngẫm aftertaste (Hậu vị) trọn vẹn là thở ra thật sâu, nuốt vào chậm hơn khi bạn uống cà phê. Bằng cách này, các thành phần chất lỏng trôi xuống 
                        cuống lưỡi và cổ họng, giúp bạn trực tiếp cảm nhận dư vị cuối cùng của cà phê. Cách này cũng giúp hương thơm cà phê thoát ra nhẹ nhàng và rõ ràng hơn. "</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="row sp20 lightgallery">
                    <div *ngFor = "let item of gallery" class="col-lg-6 col-md-6 col-6">
                        <div class="dlab-gallery-box">
                            <div class="dlab-thum">
                                <span data-exthumbimage="assets/images/gallery/pic1.jpg" data-src="images/gallery/orignal/pic1.jpg" class="check-km" title="Light Gallery Grid 1">	
                                    <img src="assets/images/gallery/{{item.image}}" alt="">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Our Info End -->
