import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
 
  pageInfo : any = {
    pageTitle:'Đăng nhập/ Đăng ký',
    //pageSubTitle:'Hơn cả những bức ảnh, nó đã là một phần của chúng tôi'
  }
  navList =  [
    {"title": "Đăng nhập", "url" : "/login"} ,
    {"title": "Đăng ký", "url" : "/signup"} ,
   
];
  constructor() { }

  ngOnInit(): void {
    
  }
  
}
