<!-- About Us -->
<div class="section-full content-inner bg-pink-light">
    <div class="container">
        <div class="row">
            <div class="col-md-6 m-b30">
                <img class="radius-sm img-cover" src="assets/images/about/pic4.jpg" alt="">
            </div>	
            <div class="col-md-6 m-b30 align-self-center">
                <div class="section-head full-head text-center">
                    <h2 class="main-title text-primary">Về chúng tôi</h2>
                    <h3 class="title">Vũ Khánh Coffee</h3>
                    <div class="dlab-separator"></div>
                    <p>Khi tình yêu chớm nở, tình yêu đẹp và lãng mạn. Đó là sự háo hức đến run rẩy con tim, là sự rung động mong ngóng, chờ đợi, là sự tươi mới đầy vui vẻ.
                         Nó ngọt ngào, dịu nhẹ, dễ chịu trong từng khoảnh khắc giống như tách capuchino. Capuchino nhiều bọt, ngọt nhẹ hòa quyện trọn vẹn giữa hương vị đặc trưng của cafe với ngọt,
                          thơm của sữa. Capuchino dễ uống mang đến cho người ta cảm giác nhẹ nhàng đẹp mắt với những tách cafe capuchino được vẽ bằng bọt sữa độc đáo, ấn tượng. Trong một ly capuchino,
                           phái nam là cafe và phái nữ là bọt sữa. Hai thứ hòa quyện vào nhau tựa như trái tim yêu đương nhiệt huyết.</p>    
                </div>
                <div class="rating-box text-center">
                    <ul class="rating-star">
                        <li><i class="fa fa-star text-primary"></i></li>
                        <li><i class="fa fa-star text-primary"></i></li>
                        <li><i class="fa fa-star text-primary"></i></li>
                        <li><i class="fa fa-star text-primary"></i></li>
                        <li><i class="fa fa-star-half-o text-primary"></i></li>
                    </ul>
                    <h2 class="rating-title">"Ly cà phê này làm tôi nhớ lại thời sinh viên!”</h2>
                    <!-- <p>“Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus.”</p> -->
                    <h4 class="rating-name">Anh Vũ</h4>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Us END -->
