import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './services/api.service';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private updateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public update$: Observable<boolean> = this.updateSubject.asObservable();

  constructor(private apiService: ApiService) {}

  // Kiểm tra xem có cần cập nhật dữ liệu không
  public checkUpdate(param: any): Observable<any> {
    return this.apiService.checkUpdate(param).pipe(
      switchMap((response: any) => {
        const time_stamp = response.time_stamp;
        const update = response.update;

        if (update) {
          console.log('update');
          this.set_local('time_update', time_stamp);
          return this.loadData();
        }else {
          // Trả về một giá trị mặc định (có thể là null hoặc một giá trị khác phù hợp với yêu cầu của bạn)
          return of(null);
        }
      })
    );
  }

  // Tải dữ liệu từ API và lưu vào sessionStorage
  private loadData(): Observable<any> {
    return this.apiService.getAllMenu().pipe(
      map((response: any) => {
        if (response?.status === true && Array.isArray(response?.menu)) {
          const menus = response.menu;
          const categoryMap = this.createCategoryMap(menus);
          this.set_local('sessionData', categoryMap);}
          else {
          console.log('Error: Invalid response or menu data missing');
        }
      })
    );
  }

  // Tạo bản đồ danh mục menu từ danh sách menu
  private createCategoryMap(menus: any[]): any {
    return menus.reduce((map, menu) => {
      const { title, price, short_description, category_id, category_title, isHot } = menu;

      if (!map[category_id]) {
        map[category_id] = {
          category_title,
          category_tagname: category_title,
          category_slug: category_id.toString(),
          items: []
        };
      }

      map[category_id].items.push({
        menu_name: title,
        cate_id: category_id,
        cate_name: category_title,
        price: price,
        short_description: short_description,
        isHot: isHot
      });

      return map;
    }, {});
  }

  // Lấy dữ liệu từ sessionStorage
  public getData(): any {
    const sessionData = localStorage.getItem('sessionData');
    return sessionData ? JSON.parse(sessionData) : null;
  }

  public set_local(key: string, value: any): void {
    localStorage.removeItem(key); // Xóa giá trị cũ
    localStorage.setItem(key, JSON.stringify(value)); // Ghi giá trị mới
  }

  public loadInfo(): void {
    const time_update = localStorage.getItem('time_update') || '-1';
    this.apiService.getInfo().subscribe(
      (response: any) => {
        if (response?.status === true && Array.isArray(response?.info)) {
          // console.log(response);
          const info = response.info;
          this.set_local('infoData', info);
        } else {
          console.log('Error: Invalid response or menu data missing');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
