import { Component, OnInit } from '@angular/core';
// import { ApiService } from '../services/api.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-our-menu',
  templateUrl: './our-menu.component.html',
  styleUrls: ['./our-menu.component.css']
})
export class OurMenuComponent implements OnInit {
  
  pageInfo : any = {
    pageTitle:'Menu của chúng tôi',
    pageSubTitle:'------Khơi gợi cảm xúc - đong đầy niềm vui------'
  }
  // constructor(private apiService: ApiService) { }
  constructor() { }
  ngOnInit(): void {
    
   
  }
}


