<!-- Events -->
<div class="section-full content-inner-2 bg-white">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div *ngFor="let item of event" class="blog-post blog-md event-blog">
                    <div class="dlab-post-media dlab-img-overlay1 dlab-overlay-primary"> 
                        <a [routerLink] = "item.url"><img src="assets/images/events/{{item.image}}" alt=""></a> 
                    </div>
                    <div class="dlab-post-info text-center">
                        <div class="dlab-post-title ">
                            <h2 class="post-sub-title"><a [routerLink] = "item.url" class="text-primary">{{item.subtitle}}</a></h2>
                            <h3 class="post-title"><a [routerLink] = "item.url">{{item.title}}</a></h3>
                        </div>
                        <div class="dlab-separator"></div>
                        <div class="dlab-post-readmore"> 
                            <a [routerLink] = "item.url" title="READ MORE" rel="bookmark" class="btn bg-secondry">LEARN MORE</a> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Events End -->
