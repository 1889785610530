import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {

  pageInfo : any = {
    pageTitle:'WE HAVE UPDATED OUR OVERALL MENU. ENJOY!',
    pageSubTitle:'OUR BLOG ARCHIVE',
    description : ''
  }

  constructor() { }

  ngOnInit(): void {
  }

}
