<!-- Owner -->
<div class="section-full bg-img-fix content-inner-2 overlay-black-middle" style="background-image:url(assets/images/background/bg3.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="owner-profile text-center text-white">
                    <div class="owner-profile-info">
                        <!-- <div class="owner-profile-pic">
                            <img src="assets/images/testimonials/pic2.jpg" alt="">
                        </div> -->
                        <div class="owner-profile-content">
                            <h2 class="owner-name">Hương Giang</h2>
                            <h5 class="owner-position text-primary">Chủ quán</h5>
                            <p>Tình yêu như ly cà phê không đường, trải qua bao nhiêu vị đắng của chông gai rồi sẽ trở về với vị ngọt đến lịm người.</p>
                            <p class="owner-signature">Hương Giang</p>
                        </div>
                    </div>	
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Owner END -->
