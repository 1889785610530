import { AudioService } from '../services/audio.service';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css']
})
export class AudioPlayerComponent implements OnInit, OnDestroy {
  audio: HTMLAudioElement;
  audioTime: number;
  subscription: Subscription;

  constructor(private audioService: AudioService) {
    this.audio = audioService.getAudio();
    const audioTimeStr = localStorage.getItem('audioTime');
    if (audioTimeStr) {
      const audioTime = parseFloat(audioTimeStr);
      audioService.setAudioTime(audioTime);
    }
    this.audio.play();
  }

  ngOnInit(): void {
    this.subscription = fromEvent(window, 'beforeunload').subscribe(() => {
      localStorage.setItem('audioTime', this.audio.currentTime.toString());
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // playAudio(): void {
  //   this.audio.play();
  // }
}
