<!-- Menu -->
<div id="targetElement"  class="section-full content-inner-2" style="background-image:url(assets/images/background/bg1.jpg); background-position: top right; background-repeat: no-repeat;">
    <ng-container *ngIf="isLoading">
        <div class="center-body ">
            <div class="container1">
                <div class="coffee-header">
                  <div class="coffee-header__buttons coffee-header__button-one"></div>
                  <div class="coffee-header__buttons coffee-header__button-two"></div>
                  <div class="coffee-header__display"></div>
                  <div class="coffee-header__details"></div>
                </div>
                <div class="coffee-medium">
                  <div class="coffe-medium__exit"></div>
                  <div class="coffee-medium__arm"></div>
                  <div class="coffee-medium__liquid"></div>
                  <div class="coffee-medium__smoke coffee-medium__smoke-one"></div>
                  <div class="coffee-medium__smoke coffee-medium__smoke-two"></div>
                  <div class="coffee-medium__smoke coffee-medium__smoke-three"></div>
                  <div class="coffee-medium__smoke coffee-medium__smoke-for"></div>
                  <div class="coffee-medium__cup"></div>
                </div>
                <div class="coffee-footer"></div>
               
            </div>
            <div class="loading-text">Đang tải vui lòng chờ

            </div>
        </div>
        
    </ng-container>
    <ng-container *ngIf="!isLoading">
        <div class="container">
            <div class="section-head text-center">
                <h3 class="title">Đồ uống</h3>
                <h4 class="sub-title">Hãy liên hệ với chúng tôi tại góc phải để được tư vấn nhé</h4>
            </div>
            <div class="dlab-tabs menu-tabs" [style.boxShadow]="boxShadowValue" >
                <ul class="nav nav-tabs">
                    <li *ngFor="let item of menuList let key = index">
                        <a data-toggle="tab" href="#{{item.category_slug}}" class="{{key == 0 ? 'active' : ''}}" (click)="changeTab(key)">
                            {{item.category_title}}
                        </a>
                        </li>
                </ul>
                <div class="tab-content ">
                    <div *ngFor="let catItem of menuList; let key = index" [id]="catItem.category_slug" class="tab-pane" [ngClass]="{ 'active': key === activeTab }">
                        <div class="menu-box">
                            <ul class="menu-list">
                                <li *ngFor="let item of catItem.items">
                                    <div class="item-left">
                                        <!-- <img src="assets/images/cup/{{item.icon}}" alt="" /> -->
                                        <h4 class="title" [ngClass]="{ 'text-primary': item.isHot === 1 }">{{item.menu_name}} <i class="fa fa-star text-primary" *ngIf="item.isHot === 1"></i></h4>
                                        <p class="description">{{item.short_description}}</p>
                                    </div>
                                    <div class="item-right">
                                        <h5 class="price">{{item.price}} xu</h5>
                                    </div>
                                </li>
                            </ul>
                            <!-- <div class="text-center">
                                <a [routerLink]="'/our-menu'" class="btn bg-secondry">Xem đầy đủ</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </ng-container>
</div>
<!-- Menu End -->
