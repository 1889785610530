<!-- Side Nav -->
<div class="sidenav full-page navbar sticky-header onepage content-scroll" malihu-scrollbar [scrollbarOptions]="scrollbarOptions" id="mySidenav" style="background-image: url(assets/images/background/bg4.jpg);">
    <a href="javascript:void(0)" class="closebtn btn" >&times;</a>
    <div class="dlab-sidenav-area">
        <div class="logo-header mostion"><a [routerLink] = "''"><img src="assets/images/logo1.png" width="193" height="89" alt=""></a></div>
        <div class="clearfix"></div>
        
        <div id="myNavbar" >
            <ul class="nav">
                <li *ngFor = "let item of navList let key = index">
                    <a  [routerLink] = "item.url" [ngClass]="['nav-link']" [routerLinkActive] = "['active']" [routerLinkActiveOptions] = "{exact:true}">{{item.title}}</a>
                </li>
            </ul>
        </div>
        <div class="footer-menu p-b10">
            <div class="address text-white">
                <p>171, đường Phùng Chí Kiên, Thành phố Vinh,Tỉnh Nghệ An</p>
            </div>
            <div class="phone text-white">
                
                    <div >
                        <p>Số điện thoại : +84 931 368 962</p>
                       
                    </div>
                    <div >
                        <p>VuKhanhCoffee@gmail.com</p>
                    </div>
                

            </div>
            <ul class="list-inline footer-social text-center m-b0">
                <li *ngFor = "let item of socialicon"><a href="https://www.facebook.com/profile.php?id=100085250785500" [ngClass] = "item.icon"></a></li>
            </ul>
            
        </div>
    </div>
</div>
<!-- Side Nav End-->

