import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  pageInfo : any = {
    pageTitle:'PARTIES & EVENTS',
    pageSubTitle:'WE WILL HOST YOUR PARTIES!',
  }

  eventPageInfo : any = 
  {
    introtext: "Got a party in mind?",
    title: "LET'S PARTY!",
    subtitle: "",
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.",
  }
  
  

  constructor() { }

  ngOnInit(): void {
  }

}
