<!-- Contact Info -->
<div class="section-full content-inner-2">
    <div class="container">
       <div class="row sp0 contact-box align-items-center">
           <div class="col-lg-6 col-md-6">
               <div class="section-head full-head text-center">
                   <h2 class="main-title text-primary">Địa chỉ của chúng tôi</h2>
                   <h3 class="title">Hãy ghé qua bạn nhé</h3>
                   <div class="dlab-separator"></div>
                   <p class="m-b0">Chúng tôi ngay bên cạnh đồng đội quán. 171 Phùng Chí Kiên, khối Tân Hòa, phường Hà Huy Tập</p>
               </div>
           </div>
           <div class="col-lg-6 col-md-6">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3779.4865979397855!2d105.68633201701022!3d18.687020734319116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3139cde04d8a690d%3A0x457f0f39472492ae!2zMTcxIFBow7luZyBDaMOtIEtpw6puLCBIw6AgSHV5IFThuq1wLCBUaMOgbmggcGjhu5EgVmluaCwgTmdo4buHIEFuLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1667290494026!5m2!1sen!2s" style="border:0; height: 300px; width: 100%; margin-bottom: -5px;" allowfullscreen=""></iframe>   
            </div>
       </div>
       <div class="row sp0 contact-box contact-box-reversed">
           <div class="col-lg-6 col-md-6">
               <img src="assets/images/our-services/images.jfif" class="img-cover1" alt="">
           </div>
           <div class="col-lg-6 col-md-6 align-self-center">
               <div class="section-head full-head text-center">
                   <h2 class="main-title text-primary">Nếu bạn có thắc mắc hoặc ý kiến</h2>
                   <h3 class="title">Vui lòng liên hệ</h3>
                   <div class="dlab-separator"></div>
                   <p class="m-b0">Số điện thoại: +84 969 955 709</p>
                   <p class="m-b0">Mail: VuKhanhCoffee@gmail.com</p>
               </div>
           </div>
       </div>
   </div>
</div>
<!-- Contact Info End -->
