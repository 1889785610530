<app-header></app-header>
<div class="page-content one-page-layout">
    <app-side-nav></app-side-nav>
    <div id="main" class="">

        <app-slider></app-slider>
        <app-menu-section></app-menu-section>
        <app-text-box-one [data]="aboutInfo"></app-text-box-one>
        <app-about-service-section></app-about-service-section>

        <app-info-section></app-info-section>
        <app-footer></app-footer>
    </div>
</div>