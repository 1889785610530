import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.css']
})
export class EventDetailComponent implements OnInit {

  pageInfo : any = {
    pageTitle:'MODERN SUSHI WORKSHOP',
    pageSubTitle:'CafeZone GALLERY',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare.',
  }

  constructor() { }

  ngOnInit(): void {
  }

}
