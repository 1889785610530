import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ElementServiceService } from '../../element-service.service';
import { Subscription } from 'rxjs';
import { SessionService } from '../../session.service';
@Component({
  selector: 'app-menu-section',
  templateUrl: './menu-section.component.html',
  styleUrls: ['./menu-section.component.css']
})
export class MenuSectionComponent implements OnInit {
  
  boxShadowValue = '5px 5px 10px rgba(0.5, 0, 0, 0)';

  menuList = []; // Danh sách menu
  isLoading = true; // Trạng thái tải dữ liệu
  activeTab = 0; // Tab đang được chọn
  firstLoad = true;

  
 
  constructor(private sessionService: SessionService) {
    

  }
  
  ngOnInit(): void {
    const time_update = localStorage.getItem('time_update') || '-1';
    this.sessionService.checkUpdate(time_update).subscribe(() => {

      console.log('Data has been updated.');
      this.handleData();

    });
  }
  

  // Hàm sự kiện thay đổi tab
  changeTab(index: number): void {

    this.activeTab = index;
    this.firstLoad = false;
  }
  handleData() {

    const sessionData = localStorage.getItem('sessionData');
    if (sessionData) {
      const rs = JSON.parse(sessionData);
      this.menuList = Object.values(rs).map((category: any) => {
        return {
          category_icon: `icon${Math.floor(Math.random() * 8) + 1}.png`,
          category_title: category.category_title,
          category_tagname: category.category_tagname,
          category_slug: category.category_slug,
          items: category.items,
        };
      });
    }
    this.isLoading = false;
  }

}
