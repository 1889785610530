import { Component, OnInit } from '@angular/core';

import { Subject } from 'rxjs';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  public scrollbarOptions = { axis: 'y', theme: 'light-thin' };
  navList =  [
      
      {"title": "Trang chủ", "url" : "/"} ,
      {"title": "Về chúng tôi", "url" : "/about"},
      {"title": "Hồi ức", "url" : "/gallery"} ,
      // {"title": "Tổ chức sự kiện và tiệc", "url" : "/events"} ,
      {"title": "Liên hệ", "url" : "/contact"} ,
      
  ];

  socialicon = [
    {icon: 'fa fa-facebook'},
    {icon: 'fa fa-instagram'},
    {icon: 'fa fa-youtube'},
    {icon: 'fa fa-google-plus'}
];
  // audio =[
  //   {audio1 : 'assets/audio/audio1.mp3'}
  // ]

  isLogin(){
    if(localStorage.getItem('userToken') != null){

    }else{
      return false;
    }
  }
  constructor(
    
  ) { }

  ngOnInit(): void {
  }

  
}
