<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.100.2/css/materialize.min.css">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<app-header></app-header>
<div class="page-content one-page-layout">
    <app-side-nav></app-side-nav>
    <div style="margin-left: 20%;">
      <app-page-banner [data]="pageInfo"></app-page-banner> 
    </div>
    <div  id="main" class="">
        <div style="margin-left: 20%;">
          <div class="card container center">
           <div>
              <ul  class="tabs tabs-fixed-width  ">
                <li class="tab" *ngFor = "let item of navList let key = index" >
                  <a [routerLink] = "item.url" [ngClass]="['nav-link']" [routerLinkActive] = "['active']" [routerLinkActiveOptions] = "{exact:true}">{{item.title}}</a>
                </li>
              </ul>
            </div>
            <div class="card-content white">
              <div >
                <router-outlet></router-outlet>
              </div>
            </div>
          </div>
        </div>
    </div>
    
   
    <app-footer></app-footer>
    
</div>