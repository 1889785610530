<!-- Menu -->
<div class="section-full content-inner-2 overlay-white-light" style="background-image:url(assets/images/background/bg1.jpg); background-position: top right; background-repeat: no-repeat;">
    <div class="container">
       <div class="section-head text-center">
           <h3 class="title">Bạn đã thử những món này chưa ?</h3>
           <h4 class="sub-title">Một số món đặc biệt chúng tôi dành cho bạn:</h4>
           <div class="dlab-separator"></div>
       </div>
       <div class="row">
           <div class="col-lg-12">
               <div class="menu-box">
                   <ul class="menu-list">
                       <li *ngFor = "let item of menuList let key = index">
                           <div class="item-left">
                               <img src="assets/images/cup/{{item.icon}}" alt=""/>
                               <h4 class="title">{{item.menu_name}}</h4>
                               <p class="description">{{item.short_description}}</p>
                           </div>
                           <div class="item-right">
                               <h5 class="price">{{item.price}} xu</h5>
                           </div>
                       </li>
                   </ul>
               </div>
           </div>
       </div>
   </div>
</div>
<!-- Menu End -->
