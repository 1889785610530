import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-section',
  templateUrl: './blog-section.component.html',
  styleUrls: ['./blog-section.component.css']
})
export class BlogSectionComponent implements OnInit {

  blog = [
    {
      image: 'pic1.jpg',
      date: 'february 21, 2020',
      category: '',
      title: 'Ten Brilliant Ways To Advertise Restaurant.',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley.', 
      url: '/blog-detail',
    },
    {
      image: 'pic2.jpg',
      date: 'february 20, 2020 ',
      category: '',
      title: 'Why Is Restaurant So Famous?',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley.', 
      url: '/blog-detail',
    },
    {
      image: 'pic3.jpg',
      date: 'february 19, 2020',
      category: '',
      title: '7 Quick Tips Regarding Restaurant.',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley.', 
      url: '/blog-detail',
    },
    {
      image: 'pic1.jpg',
      date: 'february 18, 2020',
      category: '',
      title: 'Ten Brilliant Ways To Advertise Restaurant.',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley.', 
      url: '/blog-detail',
    },
    {
      image: 'pic2.jpg',
      date: 'february 17, 2020 ',
      category: '',
      title: 'Why Is Restaurant So Famous?',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley.', 
      url: '/blog-detail',
    }
  ];
  
  constructor() { }

  ngOnInit(): void {
  }

}
