import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Response } from "@angular/http";
import { Observable } from 'rxjs';

import { catchError, map } from 'rxjs/operators';
import { User } from './user.model';


@Injectable()
export class UserService {
  readonly rootUrl = 'http://127.0.0.1:8000';
  constructor(private http: HttpClient) { }

  registerUser(user: User) {
    const body = {
      name: user.UserName,
      email: user.Email,
      password: user.Password,
      phone: user.Phone,
      address: user.Address
    }
    var reqHeader = new HttpHeaders({'No-Auth':'True'});
    return this.http.post(this.rootUrl + '/api/auth/register', body,{headers : reqHeader});
  }

  userAuthentication(userName, password) {
    const fd = new FormData();
    fd.set('email',userName);
    fd.set('password',password);
    // console.log(this.http.post(this.rootUrl + '/api/auth/login',fd));
    var reqHeader = new HttpHeaders({ 'No-Auth':'True' });
    return this.http.post(this.rootUrl + '/api/auth/login',fd,{ headers: reqHeader });
  }

  getUserClaims(){
   return  this.http.get(this.rootUrl+'/api/GetUserClaims');
  }

}