import { Component, OnInit } from '@angular/core';
declare var $:any;
@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  pageInfo : any = {
    pageTitle:'RESERVATION',
    pageSubTitle:'CafeZone CAFE & RESTAURANT'
  }

  constructor() { }

  ngOnInit(): void {

    $(document).ready(function () {
      $('.selectpicker').selectpicker();
    })
  }

}
