import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';

import { OurMenuComponent } from './our-menu/our-menu.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { ReservationComponent } from './reservation/reservation.component';
import { GalleryComponent } from './gallery/gallery.component';
import { EventsComponent } from './events/events.component';
import { BlogDetailComponent } from './blog-detail/blog-detail.component';
import { EventDetailComponent } from './event-detail/event-detail.component';
const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'our-menu', component: OurMenuComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'reservation', component: ReservationComponent},
  {path: 'gallery', component: GalleryComponent},
  {path: 'events', component: EventsComponent},
  {path: 'blog-detail', component: BlogDetailComponent},
  {path: 'event-detail', component: EventDetailComponent},
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
