<!-- Footer -->
<footer class="site-footer" style="margin: 0; padding: 0;">
    <!-- footer top part -->
    <div class="footer-top" style="margin: 0; padding-top: 1%; padding-bottom: 1%;">
        <div class="container-fluid">
            <div class="row ">
                <div class="col-lg-4 col-md-4">

                    <div class="address text-white">
                        <h5>Địa chỉ</h5>
                        <p>171, đường Phùng Chí Kiên, Thành phố Vinh,Tỉnh Nghệ An</p>
                    </div>
                    <div class="phone text-white">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <h5>Số điện thoại</h5>
                                <p>+84 931 368 962</p>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <h5>Địa chỉ Email</h5>
                                <p>VuKhanhCoffee@gmail.com</p>
                            </div>
                        </div>

                    </div>
                    <div class="text-left">
                        <ul class="list-inline footer-social m-b0">
                            <li *ngFor="let item of socialicon"><a href="https://www.facebook.com/profile.php?id=100085250785500" [ngClass]="item.icon"></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="text-left text-white">
                        <div class="row">
                            <!-- ... -->
                            <div class="col-md-12">
                                <div class="partners">
                                    <h5>Các đơn vị hợp tác</h5>
                                    <div class="partner-logos">
                                        <img src="assets/images/Shopee-Food-Logo.png" alt="Logo 1">
                                        <img src="assets/images/gf.png" alt="Logo 2">
                                        <img src="assets/images/hey.png" alt="Logo 3">
                                        <!-- Thêm các logo khác tương tự -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="text-left text-white">
                        <div class="map-wrapper">
                            <div class="map">
                                <!-- Nhúng Google Map -->
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3779.4865979397855!2d105.68633201701022!3d18.687020734319116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3139cde04d8a690d%3A0x457f0f39472492ae!2zMTcxIFBow7luZyBDaMOtIEtpw6puLCBIw6AgSHV5IFThuq1wLCBUaMOgbmggcGjhu5EgVmluaCwgTmdo4buHIEFuLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1667290494026!5m2!1sen!2s"
                                    style="border:0; height: auto; width: 100%; margin-bottom: -5px;"
                                    allowfullscreen="">
                                </iframe>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- Messenger Plugin chat Code -->





</footer>
<!-- Footer END -->