import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

  pageInfo : any = {
    pageTitle:'Hồi ức',
    pageSubTitle:'Hơn cả những bức ảnh, nó đã là một phần của chúng tôi'
  }
  
  constructor() { }

  ngOnInit(): void {
  }

}
