<!-- Menu -->
<!-- <p #marqueeText [@marqueeAnimation]="state">Nếu có vấn đề về chọn món hãy liên hệ với chúng tôi bằng cách chat ở góc phải dưới cùng bạn nhé !!!</p> -->
<div class="section-full bg-pink-light" style="margin-top: 3%;">
    <div class="container">
        <ng-container *ngIf="isLoading; else menuContent">
            <div class="center-body">
                <div class="container1">
                    <div class="coffee-header">
                        <div class="coffee-header__buttons coffee-header__button-one"></div>
                        <div class="coffee-header__buttons coffee-header__button-two"></div>
                        <div class="coffee-header__display"></div>
                        <div class="coffee-header__details"></div>
                    </div>
                    <div class="coffee-medium">
                        <div class="coffe-medium__exit"></div>
                        <div class="coffee-medium__arm"></div>
                        <div class="coffee-medium__liquid"></div>
                        <div class="coffee-medium__smoke coffee-medium__smoke-one"></div>
                        <div class="coffee-medium__smoke coffee-medium__smoke-two"></div>
                        <div class="coffee-medium__smoke coffee-medium__smoke-three"></div>
                        <div class="coffee-medium__smoke coffee-medium__smoke-for"></div>
                        <div class="coffee-medium__cup"></div>
                    </div>
                    <div class="coffee-footer"></div>
                </div>
                <div class="loading-text">Đang tải vui lòng chờ</div>
            </div>
        </ng-container>
        <ng-template #menuContent>
            <div class="container">
                <div class=" style1">
                  <div class="row">
                    <div class="col-lg-2 col-2 flow-scroll" *ngIf="isScrolling"> 
                        <ul class="nav flex-column">
                          <ng-container *ngFor="let item of menuList; let key = index">
                            <li [ngClass]="{ 'nav-item': true, 'active': key === activeTab }">
                              <a class="nav-link" data-toggle="tab" [class.active]="key === 0" href="#{{item.category_slug}}" (click)="changeTab(key)">
                                <span class="category-title">{{item.category_title}}</span>
                              </a>
                            </li>
                          </ng-container>
                        </ul>
                    </div>
                      
                    <div class="col-lg-10 col-10">
                      <div class="tab-content">
                        <div *ngFor="let catItem of menuList; let key = index" [id]="catItem.category_slug" class="tab-pane" [ngClass]="{ 'active': key === activeTab }">
                          <div class="menu-box">
                            <ul class="menu-list">
                              <li *ngFor="let item of catItem.items">
                                <div class="item-left">
                                  <h4 class="title" [ngClass]="{ 'text-primary': item.isHot === 1 }">{{item.menu_name}} <i class="fa fa-star text-primary" *ngIf="item.isHot === 1"></i></h4>
                                  <p class="description">{{item.short_description}}</p>
                                </div>
                                <div class="item-right">
                                  <h5 class="price">{{item.price}} xu</h5>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
        </ng-template>
    </div>
</div>
<!-- Menu End -->
