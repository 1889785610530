<div class="section-full content-inner-2 bg-pink-light">
    <div class="container">
        <div class="section-head text-center m-b0">
            <h2 class="main-title text-primary">{{data.introtext}}</h2>
            <h3 class="title">{{data.title}}</h3>
            <h4 class="sub-title">{{data.subtitle}}</h4>
            <div class="dlab-separator"></div>
            <p class="m-b0">{{data.description}}</p>
        </div>
    </div>
</div>
