import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private url =" https://api.vukhanh.cafe/";
  private apiUrl_getMenu = this.url +'api/menu/category/';
  private apiUrl_getItemHot =this.url +'api/menu/getItemHot/'
  private apiUrl_checkUpdate =this.url +'api/checkUpdate/'
  private apiUrl_getAllMenu =this.url +'api/menu/getall/'
  private apiUrl_getInfo =this.url +'api/info/getall/'
  constructor(private http: HttpClient) { }

  getMenuByCategory(cid) {
    return this.http.get(this.apiUrl_getMenu + cid);
  }
  checkUpdate(timestamp){
    return this.http.get(this.apiUrl_checkUpdate + timestamp);
  }
  getItemHotByCategory(cid) {
    return this.http.get(this.apiUrl_getItemHot + cid);
  }
  getAllMenu(){
    return this.http.get(this.apiUrl_getAllMenu);
  }
  getInfo(){
    return this.http.get(this.apiUrl_getInfo);
  }
}