
import { Component, OnInit, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../services/api.service';
import { forkJoin } from 'rxjs';
import { SessionService } from '../../session.service';
import { Observable } from 'rxjs'; // Import Observable
@Component({
  selector: 'app-our-menu-one',
  templateUrl: './our-menu-one.component.html',
  styleUrls: ['./our-menu-one.component.css'],


})






export class OurMenuOneComponent implements OnInit {

  menuList = []; // Danh sách menu
  isLoading = true; // Trạng thái tải dữ liệu
  activeTab = 0; // Tab đang được chọn
  firstLoad = true;
  isScrolling = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (!this.isLoading) {
      this.isScrolling = true;
    }
  }
  constructor(private sessionService: SessionService) {


  }

  ngOnInit(): void {
    const time_update = localStorage.getItem('time_update') || '-1';
    this.sessionService.checkUpdate(time_update).subscribe(() => {
      
        console.log('Data has been updated.');
        this.handleData();
      
    });
  }



  // Hàm sự kiện thay đổi tab
  changeTab(index: number): void {

    this.activeTab = index;
  }
  handleData() {
    
    const sessionData = localStorage.getItem('sessionData');
    if (sessionData) {
      const rs = JSON.parse(sessionData);
      this.menuList = Object.values(rs).map((category: any) => {
        return {
          category_icon: `icon${Math.floor(Math.random() * 8) + 1}.png`,
          category_title: category.category_title,
          category_tagname: category.category_tagname,
          category_slug: category.category_slug,
          items: category.items,
        };
      });
    }
    this.isLoading = false;
  }

}