<app-header></app-header>
<div class="page-content one-page-layout">
    <app-side-nav></app-side-nav>
    <div id="main" class="">
        <app-page-banner [data]="pageInfo"></app-page-banner>
        <app-text-box-one [data]="eventPageInfo" ></app-text-box-one>
        
        <app-event-section></app-event-section>
        <app-footer></app-footer>
    </div>
</div>
