import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-detail-section',
  templateUrl: './event-detail-section.component.html',
  styleUrls: ['./event-detail-section.component.css']
})
export class EventDetailSectionComponent implements OnInit {

  menuList = [
    {
      image:  "cup1.png",
      title: "Single Cup Brew",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing",
      price: "4.50",
    },
    {
      image:  "cup2.png",
      title: "Black Eyed Andy",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing",
      price: "4.99",
    },
    {
      image:  "cup3.png",
      title: "Cuban Shot",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing",
      price: "2.55",
    },
    {
      image:  "cup4.png",
      title: "Cafe au Lait",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing",
      price: "2.55",
    },
    {
      image:  "cup5.png",
      title: "Coffee of the Day",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing",
      price: "2.55",
    },
    {
      image:  "cup6.png",
      title: "Cappuccino",
      description: "Lorem ipsum dolor sit amet consectetur adipiscing",
      price: "2.55",
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
