<!-- About Services -->
<div class="section-full bg-img-fix content-inner overlay-black-middle" style="background-image:url(assets/images/background/bg2.jpg);">
    <div class="container">
        <div class="section-head text-center">
            <h3 class="title text-white">BẠN ĐÃ BIẾT ?</h3>
            <!-- <h4 class="sub-title">ABOUT OUR RESTAURANT:</h4> -->
        </div>
        <div class="row">
            <div *ngFor="let item of service" class="col-lg-3 col-md-3 col-sm-6 col-6 m-b30">
                <div class="icon-bx-wraper center text-white service-box">
                    <div class="icon-bx-xl"> 
                        <span class="icon-cell"><img src="assets/images/icons/{{item.image}}" alt=""></span> 
                    </div>
                    <div class="icon-content">
                        <h5 class="dlab-tilte">{{item.title}}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- About Services END -->
