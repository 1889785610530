<!-- inner page banner -->
<div class="dlab-bnr-inr overlay-black-light" style="background-image:url(assets/images/banner/bnr1.jpg); background-position: bottom right;">
    <div class="container">
        <div class="dlab-bnr-inr-entry">
            <h1 class="text-white">{{ data.pageTitle }}</h1>
            <p class="sub-title">{{ data.pageSubTitle }}</p>
            
            <p [ngClass] = "['text-white']" *ngIf="data.description">
                {{data.description}}
            </p>
        </div>
    </div>
</div>
<!-- inner page banner END -->
