<app-header></app-header>
<div class="page-content one-page-layout">
    <app-side-nav></app-side-nav>
    <div id="main" class="">
        <app-page-banner [data]="pageInfo"></app-page-banner>
        <app-contact-form></app-contact-form>
        <app-contact-info></app-contact-info>
        <app-footer></app-footer>
    </div>
</div>
