<!-- Contact Form -->
<div class="section-full content-inner bg-pink-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 m-b30 align-self-center">
                <div class="contact-form">
                    <div class="section-head full-head text-center">
                        <h2 class="main-title text-primary">Bạn có câu hỏi hoặc ý kiến ?</h2>
                        <h3 class="title">Gửi cho chúng tôi</h3>
                        <div class="dlab-separator"></div>
                    </div>
                    <div class="dzFormMsg"></div>
                    <form method="post" class="dzForm" action="script/contact.php">
                        <input type="hidden" value="Contact" name="dzToDo" >
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input name="dzName" type="text" required class="form-control" placeholder="Tên của bạn">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group"> 
                                        <input name="dzEmail" type="email" class="form-control" required  placeholder="Địa chỉ Email" >
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input name="dzOther[Phone]" type="text" required class="form-control" placeholder="Số điện thoại">
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <div class="input-group">
                                        <textarea name="dzMessage" rows="4" class="form-control" required placeholder="Tin nhắn của bạn..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button name="submit" type="submit" value="Submit" class="btn btn-block bg-secondry"> <span>Gửi</span> </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>	
            <div class="col-lg-6 col-md-12 m-b30">
                <div class="contact-reserve text-white text-uppercase">
                    <img class="img-cover" src="assets/images/about/pic1.jpg" alt="">
                    <div class="contact-text">
                        <!-- <h2 class="title">Need a table?</h2>
                        <h6 class="sub-title">Make your reservation online!</h6>
                        <a [routerLink] = "'/contact'" class="btn">Reserve now!</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Contact Form END -->
